import React, {  lazy } from "react";

const Navbar = lazy(()=>import("./Navbar/Navbar"));
const Header = lazy(()=>import("./Header/Header"));
const Skills = lazy(()=>import("./Skills/Skills"))
const Projects = lazy(()=>import("./Projects/Project"))
const Education = lazy(()=>import("./Education/education"));
const Interests = lazy(()=>import("./Interests/Interests"));
const Experience = lazy(()=>import("./Experience/Experience"));
const Footer = lazy(()=>import("./Footer/Footer"));

const Index = () => {
  return (
    <>
      <Navbar/>
      <Header />
      <Education />
      <Skills />
      <Projects />
      <Experience />
      <Interests/>
      <Footer />
    </>
  );
};

export default Index;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  lng: 'en', 
  fallbackLng: 'en', 
  debug: false, 
  interpolation: {
    escapeValue: false, 
  },
  resources: {
    en: {
      translation: require('./utils/en.json'),
    },
    fr: {
      translation: require('./utils/fr.json'),
    },

  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <I18nextProvider i18n={i18n}>
         <App />
      </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();

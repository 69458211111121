import { lazy, useState } from "react";
import "./App.css";
import Index from "./components/Index";
const ThemeSwitcher = lazy(()=>import("./components/ThemeSwitch/ThemeSwitcher"));

function App() {
  const [lightmode, setlightmode] = useState(false)
  const togglelightmode = () =>{
    setlightmode(!lightmode)
  }
  return (
    <div className={`App curtom-cursor ${lightmode ? 'light-mode' : ''}`}>
      <div  className="bg-image"></div>
      <ThemeSwitcher togglelightmode={togglelightmode} />
      <Index />
    </div>
  );
}

export default App;
